.numeric__modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 40;
}

.numeric__keyboard {
  background-color: var(--white-color);
  color: var(--dark-color);
}

.keyboard__container {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(4, 100px);
}

.keyboard__cell {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px var(--grey-color);
  cursor: pointer;
}

.keyboard__cell:active {
  box-shadow: inset 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}

.keyboard__cell--bordered {
  border-left: solid 1px var(--grey-color);
  border-right: solid 1px var(--grey-color);
}

.numeric__value {
  padding: 10px;
  text-align: right;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-bottom: solid 1px var(--grey-color);
}

.numeric__value span {
  font-size: 46px;
  font-weight: 700;
}
