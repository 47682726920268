:root {
  --primary-color: hsl(221, 100%, 64%);
  --grey-color: hsl(220, 29%, 90%);
  --grey2-color: hsl(0, 0%, 96%);
  --darkGrey-color: hsl(0, 0%, 30%);
  --black-color: hsl(0, 0%, 0%);
  --white-color: hsl(0, 0%, 100%);
}

html {
  background-color: hsl(210, 29%, 97%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 10, 10, 0.86);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.modal--center {
  justify-content: center;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.modal--top {
  margin: 20px auto;
}

.modal--auto {
  width: auto;
}

.modal__container {
  width: 100%;
}

.modal--large {
  width: 90%;
}

.refresh__pwa {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 42;
}

.icon--space {
  margin: 0 10px;
}
