@charset "utf-8";

$primary: hsl(221, 100%, 64%);

@import '~bulma/bulma';
@import '~bulma-extensions/bulma-steps/src/sass/index';

.material-icons {
  &.is-small {
    font-size: 1rem;
  }

  &.is-medium {
    font-size: 2rem;
  }

  &.is-large {
    font-size: 3rem;
  }
}
